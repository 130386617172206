import { NgModule } from '@angular/core';
import { LoadingDialogComponent } from './components/loading/loading-dialog/loading-dialog.component';
import { ErrorDialogComponent } from './components/errors/error-dialog/error-dialog.component'
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { LoadingDialogService } from './services/loading/loading-dialog.service';
import { ErrorDialogService } from './services/error-dialog/error-dialog.service';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
// import { CampaignsComponent } from './components/campaigns/campaigns.component';


const sharedComponents = [LoadingDialogComponent, ErrorDialogComponent,
    // CampaignsComponent
];

@NgModule({
declarations: [...sharedComponents],
  imports: [CommonModule, RouterModule, MatDialogModule, MatProgressSpinnerModule],
  exports: [...sharedComponents],
  providers: [ErrorDialogService, LoadingDialogService],
  entryComponents: [...sharedComponents]
})
export class SharedModule {}
